import type { Ref } from 'vue';
import type { Swiper as SwiperClass } from 'swiper/types';

interface ISetCustomSwiper {
    nextSlide: () => void,
    prevSlide: () => void,
    setSliderRef: (instance: SwiperClass) => void
}

export const setCustomSwiper = (swiperRef: Ref<SwiperClass | null>): ISetCustomSwiper => {
  const nextSlide = () => {
    swiperRef.value?.slideNext(1000);
  };

  const prevSlide = () => {
    swiperRef.value?.slidePrev(1000);
  };

  const setSliderRef = (swiperInstance: SwiperClass) => {
    swiperRef.value = swiperInstance;
  };

  return {
    nextSlide,
    prevSlide,
    setSliderRef
  };
};
